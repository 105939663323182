@media screen and (width <= 414px) {
    .footer {
        width: 100%;
    }
}

.Footer {
    display: flex;
    flex-direction: column;
    background-color: #CBC0AF;
    overflow: auto;
}

.butterfly {
  display: flex;
  
}

.follow {
    border: none;
    border-radius: 12px;
    background-color: #EDD2C4;
    color: black;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    flex: 30%;
}

.follow:hover {
    box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
}

.Credit {
    font-family: 'Montserrat';
}

