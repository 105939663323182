@media screen and (width <= 414px) {
    .contact {
        width: 100%;
    }
}

.contact {
    height: 500px;
    width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: whitesmoke;
    padding-bottom: 50px;
    border: 1px solid #ccc;
    border-radius: 5px;
    
}

label {
    font-family:'Quicksand';
    display: block;
    margin: 5px;
    padding: 5px;
}

input, select {
    padding: 7px;
    height: 30px;
    width: 70%;
    outline: none;
    border-radius: 5px;
    border: 1px solid rgb(182, 182, 182);
}

input:focus {
    border: 1px solid rgb(0, 208, 255);
}

h1 {
    font-family: 'Agbalumo';
}

h3 {
    font-family: 'Quicksand';
}

.textarea {
    display: block;
    padding: 7px;
    width: 80%;
    outline: none;
    border-radius: 5px;
    border: 1px solid rgb(182, 182, 182);
}

.submit {
    display: block;
    align-items: center;
}

.phone {
    width: 90px;
    height: 90px;
    padding-top: 20px;
    margin: 20px;
}

a.number:link {
    color: #000000;
    text-decoration: none;
}

a.number:visited {
    color: #000000;
    text-decoration: none;
}

a.number:hover {
    color: #000000;
    text-decoration: none;
}

a.number:active {
    color: #000000;
    text-decoration: none;
}