@media screen and (width <= 375px) {
    .resources {
        width: 100%;
    }
}


.resources {
    font-family: 'Agbalumo';
}


.download {
    border: none;
    border-radius: 12px;
    background-color: #EDD2C4;
    color: black;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
}

.download:hover {
    box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
}